@use '../../../sass/theme/app_variables' as *;

.welcome-screen {
  position: absolute;
  width: 100%;
  height: 100vh;
  background: linear-gradient(135deg, rgba($primary, .95), rgba($primary, .975));
  z-index: 999999;
  display: flex;
  animation: Welcome 3s ease;

  .message {
    padding: 1rem;
    color: white;
    opacity: .9;
    font-size: 2rem;
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: auto;
    animation: Message .5s ease;
  }

  @keyframes Message {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes Welcome {
    0% {
      opacity: 1;
    }

    83% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }
}