@use '../../../../sass/theme/app_variables' as *;

.title1 {
  font-family: $font-family;
  font-size: 2rem;
  font-weight: 400;
  line-height: 125%;
}

.title2 {
  font-family: $font-family;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 125%;
}

.title3 {
  font-family: $font-family;
  font-size: 1rem;
  font-weight: 700;
  line-height: 125%;
}


.title4 {
  font-family: $font-family;
  font-size: 1rem;
  font-weight: 600;
  line-height: 125%;
}

.title5 {
  font-family: $font-family;
  font-size: 1rem;
  font-weight: 500;
  line-height: 125%;
}

.title6 {
  font-family: $font-family;
  font-size: .875rem;
  font-weight: 400;
  line-height: 125%;
}