.row {
    box-sizing: border-box;
    margin: 0 auto;
    min-width: 100%;
    display: -ms-flexbox;
    display: flex;
    margin: 0 -.5rem;
    -ms-flex: 0 1 auto;
        flex: 0 1 auto;
    -ms-flex-direction: row;
        flex-direction: row;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  
  .row.reverse {
    -ms-flex-direction: row-reverse;
        flex-direction: row-reverse;
  }
  
  .row.natural-height {
    -ms-flex-align: start;
        align-items: flex-start;
  }
  
  .row.flex-column {
    -ms-flex-direction: column;
        flex-direction: column;
  }
  
  .col {
    box-sizing: border-box;
    -ms-flex-positive: 1;
        flex-grow: 1;
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    max-width: 100%;
    padding: .5rem;
  }
  
  .col.reverse {
    -ms-flex-direction: column-reverse;
        flex-direction: column-reverse;
  }
  
  .first {
    -ms-flex-order: -1;
        order: -1;
  }
  
  .last {
    -ms-flex-order: 1;
        order: 1;
  }
  
  .align-start {
    -ms-flex-item-align: start;
        align-self: flex-start;
  }
  
  .align-end {
    -ms-flex-item-align: end;
        align-self: flex-end;
  }
  
  .align-center {
    -ms-flex-item-align: center;
        -ms-grid-row-align: center;
        align-self: center;
  }
  
  .align-baseline {
    -ms-flex-item-align: baseline;
        align-self: baseline;
  }
  
  .align-stretch {
    -ms-flex-item-align: stretch;
        -ms-grid-row-align: stretch;
        align-self: stretch;
  }
  
  .col-xs {
    box-sizing: border-box;
    -ms-flex-positive: 1;
        flex-grow: 1;
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    max-width: 100%;
    padding: .5rem;
  }
  
  .col-xs-1 {
    box-sizing: border-box;
    -ms-flex-preferred-size: 8.3333333333%;
        flex-basis: 8.3333333333%;
    max-width: 8.3333333333%;
    padding: .5rem;
  }
  
  .col-xs-offset-1 {
    margin-left: 8.3333333333%;
  }
  
  .col-xs-2 {
    box-sizing: border-box;
    -ms-flex-preferred-size: 16.6666666667%;
        flex-basis: 16.6666666667%;
    max-width: 16.6666666667%;
    padding: .5rem;
  }
  
  .col-xs-offset-2 {
    margin-left: 16.6666666667%;
  }
  
  .col-xs-3 {
    box-sizing: border-box;
    -ms-flex-preferred-size: 25%;
        flex-basis: 25%;
    max-width: 25%;
    padding: .5rem;
  }
  
  .col-xs-offset-3 {
    margin-left: 25%;
  }
  
  .col-xs-4 {
    box-sizing: border-box;
    -ms-flex-preferred-size: 33.3333333333%;
        flex-basis: 33.3333333333%;
    max-width: 33.3333333333%;
    padding: .5rem;
  }
  
  .col-xs-offset-4 {
    margin-left: 33.3333333333%;
  }
  
  .col-xs-5 {
    box-sizing: border-box;
    -ms-flex-preferred-size: 41.6666666667%;
        flex-basis: 41.6666666667%;
    max-width: 41.6666666667%;
    padding: .5rem;
  }
  
  .col-xs-offset-5 {
    margin-left: 41.6666666667%;
  }
  
  .col-xs-6 {
    box-sizing: border-box;
    -ms-flex-preferred-size: 50%;
        flex-basis: 50%;
    max-width: 50%;
    padding: .5rem;
  }
  
  .col-xs-offset-6 {
    margin-left: 50%;
  }
  
  .col-xs-7 {
    box-sizing: border-box;
    -ms-flex-preferred-size: 58.3333333333%;
        flex-basis: 58.3333333333%;
    max-width: 58.3333333333%;
    padding: .5rem;
  }
  
  .col-xs-offset-7 {
    margin-left: 58.3333333333%;
  }
  
  .col-xs-8 {
    box-sizing: border-box;
    -ms-flex-preferred-size: 66.6666666667%;
        flex-basis: 66.6666666667%;
    max-width: 66.6666666667%;
    padding: .5rem;
  }
  
  .col-xs-offset-8 {
    margin-left: 66.6666666667%;
  }
  
  .col-xs-9 {
    box-sizing: border-box;
    -ms-flex-preferred-size: 75%;
        flex-basis: 75%;
    max-width: 75%;
    padding: .5rem;
  }
  
  .col-xs-offset-9 {
    margin-left: 75%;
  }
  
  .col-xs-10 {
    box-sizing: border-box;
    -ms-flex-preferred-size: 83.3333333333%;
        flex-basis: 83.3333333333%;
    max-width: 83.3333333333%;
    padding: .5rem;
  }
  
  .col-xs-offset-10 {
    margin-left: 83.3333333333%;
  }
  
  .col-xs-11 {
    box-sizing: border-box;
    -ms-flex-preferred-size: 91.6666666667%;
        flex-basis: 91.6666666667%;
    max-width: 91.6666666667%;
    padding: .5rem;
  }
  
  .col-xs-offset-11 {
    margin-left: 91.6666666667%;
  }
  
  .col-xs-12 {
    box-sizing: border-box;
    -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
    max-width: 100%;
    padding: .5rem;
  }
  
  .col-xs-offset-12 {
    margin-left: 100%;
  }
  
  .row.start-xs {
    -ms-flex-pack: start;
        justify-content: flex-start;
  }
  
  .row.center-xs {
    -ms-flex-pack: center;
        justify-content: center;
  }
  
  .row.end-xs {
    -ms-flex-pack: end;
        justify-content: flex-end;
  }
  
  .row.top-xs {
    -ms-flex-align: start;
        align-items: flex-start;
  }
  
  .row.middle-xs {
    -ms-flex-align: center;
        align-items: center;
  }
  
  .row.bottom-xs {
    -ms-flex-align: end;
        align-items: flex-end;
  }
  
  .row.around-xs {
    -ms-flex-pack: distribute;
        justify-content: space-around;
  }
  
  .row.between-xs {
    -ms-flex-pack: justify;
        justify-content: space-between;
  }
  
  .first-xs {
    -ms-flex-order: -1;
        order: -1;
  }
  
  .last-xs {
    -ms-flex-order: 1;
        order: 1;
  }
  
  @media only screen and (min-width: 4.5rem) {
    .col-sm {
      box-sizing: border-box;
      -ms-flex-positive: 1;
          flex-grow: 1;
      -ms-flex-preferred-size: 0;
          flex-basis: 0;
      max-width: 100%;
      padding: .5rem;
    }
    .col-sm-1 {
      box-sizing: border-box;
      -ms-flex-preferred-size: 8.3333333333%;
          flex-basis: 8.3333333333%;
      max-width: 8.3333333333%;
      padding: .5rem;
    }
    .col-sm-offset-1 {
      margin-left: 8.3333333333%;
    }
    .col-sm-2 {
      box-sizing: border-box;
      -ms-flex-preferred-size: 16.6666666667%;
          flex-basis: 16.6666666667%;
      max-width: 16.6666666667%;
      padding: .5rem;
    }
    .col-sm-offset-2 {
      margin-left: 16.6666666667%;
    }
    .col-sm-3 {
      box-sizing: border-box;
      -ms-flex-preferred-size: 25%;
          flex-basis: 25%;
      max-width: 25%;
      padding: .5rem;
    }
    .col-sm-offset-3 {
      margin-left: 25%;
    }
    .col-sm-4 {
      box-sizing: border-box;
      -ms-flex-preferred-size: 33.3333333333%;
          flex-basis: 33.3333333333%;
      max-width: 33.3333333333%;
      padding: .5rem;
    }
    .col-sm-offset-4 {
      margin-left: 33.3333333333%;
    }
    .col-sm-5 {
      box-sizing: border-box;
      -ms-flex-preferred-size: 41.6666666667%;
          flex-basis: 41.6666666667%;
      max-width: 41.6666666667%;
      padding: .5rem;
    }
    .col-sm-offset-5 {
      margin-left: 41.6666666667%;
    }
    .col-sm-6 {
      box-sizing: border-box;
      -ms-flex-preferred-size: 50%;
          flex-basis: 50%;
      max-width: 50%;
      padding: .5rem;
    }
    .col-sm-offset-6 {
      margin-left: 50%;
    }
    .col-sm-7 {
      box-sizing: border-box;
      -ms-flex-preferred-size: 58.3333333333%;
          flex-basis: 58.3333333333%;
      max-width: 58.3333333333%;
      padding: .5rem;
    }
    .col-sm-offset-7 {
      margin-left: 58.3333333333%;
    }
    .col-sm-8 {
      box-sizing: border-box;
      -ms-flex-preferred-size: 66.6666666667%;
          flex-basis: 66.6666666667%;
      max-width: 66.6666666667%;
      padding: .5rem;
    }
    .col-sm-offset-8 {
      margin-left: 66.6666666667%;
    }
    .col-sm-9 {
      box-sizing: border-box;
      -ms-flex-preferred-size: 75%;
          flex-basis: 75%;
      max-width: 75%;
      padding: .5rem;
    }
    .col-sm-offset-9 {
      margin-left: 75%;
    }
    .col-sm-10 {
      box-sizing: border-box;
      -ms-flex-preferred-size: 83.3333333333%;
          flex-basis: 83.3333333333%;
      max-width: 83.3333333333%;
      padding: .5rem;
    }
    .col-sm-offset-10 {
      margin-left: 83.3333333333%;
    }
    .col-sm-11 {
      box-sizing: border-box;
      -ms-flex-preferred-size: 91.6666666667%;
          flex-basis: 91.6666666667%;
      max-width: 91.6666666667%;
      padding: .5rem;
    }
    .col-sm-offset-11 {
      margin-left: 91.6666666667%;
    }
    .col-sm-12 {
      box-sizing: border-box;
      -ms-flex-preferred-size: 100%;
          flex-basis: 100%;
      max-width: 100%;
      padding: .5rem;
    }
    .col-sm-offset-12 {
      margin-left: 100%;
    }
    .row.start-sm {
      -ms-flex-pack: start;
          justify-content: flex-start;
    }
    .row.center-sm {
      -ms-flex-pack: center;
          justify-content: center;
    }
    .row.end-sm {
      -ms-flex-pack: end;
          justify-content: flex-end;
    }
    .row.top-sm {
      -ms-flex-align: start;
          align-items: flex-start;
    }
    .row.middle-sm {
      -ms-flex-align: center;
          align-items: center;
    }
    .row.bottom-sm {
      -ms-flex-align: end;
          align-items: flex-end;
    }
    .row.around-sm {
      -ms-flex-pack: distribute;
          justify-content: space-around;
    }
    .row.between-sm {
      -ms-flex-pack: justify;
          justify-content: space-between;
    }
    .first-sm {
      -ms-flex-order: -1;
          order: -1;
    }
    .last-sm {
      -ms-flex-order: 1;
          order: 1;
    }
  }
  
  @media only screen and (min-width: 65rem) {
    .col-md {
      box-sizing: border-box;
      -ms-flex-positive: 1;
          flex-grow: 1;
      -ms-flex-preferred-size: 0;
          flex-basis: 0;
      max-width: 100%;
      padding: .5rem;
    }
    .col-md-1 {
      box-sizing: border-box;
      -ms-flex-preferred-size: 8.3333333333%;
          flex-basis: 8.3333333333%;
      max-width: 8.3333333333%;
      padding: .5rem;
    }
    .col-md-offset-1 {
      margin-left: 8.3333333333%;
    }
    .col-md-2 {
      box-sizing: border-box;
      -ms-flex-preferred-size: 16.6666666667%;
          flex-basis: 16.6666666667%;
      max-width: 16.6666666667%;
      padding: .5rem;
    }
    .col-md-offset-2 {
      margin-left: 16.6666666667%;
    }
    .col-md-3 {
      box-sizing: border-box;
      -ms-flex-preferred-size: 25%;
          flex-basis: 25%;
      max-width: 25%;
      padding: .5rem;
    }
    .col-md-offset-3 {
      margin-left: 25%;
    }
    .col-md-4 {
      box-sizing: border-box;
      -ms-flex-preferred-size: 33.3333333333%;
          flex-basis: 33.3333333333%;
      max-width: 33.3333333333%;
      padding: .5rem;
    }
    .col-md-offset-4 {
      margin-left: 33.3333333333%;
    }
    .col-md-5 {
      box-sizing: border-box;
      -ms-flex-preferred-size: 41.6666666667%;
          flex-basis: 41.6666666667%;
      max-width: 41.6666666667%;
      padding: .5rem;
    }
    .col-md-offset-5 {
      margin-left: 41.6666666667%;
    }
    .col-md-6 {
      box-sizing: border-box;
      -ms-flex-preferred-size: 50%;
          flex-basis: 50%;
      max-width: 50%;
      padding: .5rem;
    }
    .col-md-offset-6 {
      margin-left: 50%;
    }
    .col-md-7 {
      box-sizing: border-box;
      -ms-flex-preferred-size: 58.3333333333%;
          flex-basis: 58.3333333333%;
      max-width: 58.3333333333%;
      padding: .5rem;
    }
    .col-md-offset-7 {
      margin-left: 58.3333333333%;
    }
    .col-md-8 {
      box-sizing: border-box;
      -ms-flex-preferred-size: 66.6666666667%;
          flex-basis: 66.6666666667%;
      max-width: 66.6666666667%;
      padding: .5rem;
    }
    .col-md-offset-8 {
      margin-left: 66.6666666667%;
    }
    .col-md-9 {
      box-sizing: border-box;
      -ms-flex-preferred-size: 75%;
          flex-basis: 75%;
      max-width: 75%;
      padding: .5rem;
    }
    .col-md-offset-9 {
      margin-left: 75%;
    }
    .col-md-10 {
      box-sizing: border-box;
      -ms-flex-preferred-size: 83.3333333333%;
          flex-basis: 83.3333333333%;
      max-width: 83.3333333333%;
      padding: .5rem;
    }
    .col-md-offset-10 {
      margin-left: 83.3333333333%;
    }
    .col-md-11 {
      box-sizing: border-box;
      -ms-flex-preferred-size: 91.6666666667%;
          flex-basis: 91.6666666667%;
      max-width: 91.6666666667%;
      padding: .5rem;
    }
    .col-md-offset-11 {
      margin-left: 91.6666666667%;
    }
    .col-md-12 {
      box-sizing: border-box;
      -ms-flex-preferred-size: 100%;
          flex-basis: 100%;
      max-width: 100%;
      padding: .5rem;
    }
    .col-md-offset-12 {
      margin-left: 100%;
    }
    .row.start-md {
      -ms-flex-pack: start;
          justify-content: flex-start;
    }
    .row.center-md {
      -ms-flex-pack: center;
          justify-content: center;
    }
    .row.end-md {
      -ms-flex-pack: end;
          justify-content: flex-end;
    }
    .row.top-md {
      -ms-flex-align: start;
          align-items: flex-start;
    }
    .row.middle-md {
      -ms-flex-align: center;
          align-items: center;
    }
    .row.bottom-md {
      -ms-flex-align: end;
          align-items: flex-end;
    }
    .row.around-md {
      -ms-flex-pack: distribute;
          justify-content: space-around;
    }
    .row.between-md {
      -ms-flex-pack: justify;
          justify-content: space-between;
    }
    .first-md {
      -ms-flex-order: -1;
          order: -1;
    }
    .last-md {
      -ms-flex-order: 1;
          order: 1;
    }
  }
  
  @media only screen and (min-width: 9.5rem) {
    .col-lg {
      box-sizing: border-box;
      -ms-flex-positive: 1;
          flex-grow: 1;
      -ms-flex-preferred-size: 0;
          flex-basis: 0;
      max-width: 100%;
      padding: .5rem;
    }
    .col-lg-1 {
      box-sizing: border-box;
      -ms-flex-preferred-size: 8.3333333333%;
          flex-basis: 8.3333333333%;
      max-width: 8.3333333333%;
      padding: .5rem;
    }
    .col-lg-offset-1 {
      margin-left: 8.3333333333%;
    }
    .col-lg-2 {
      box-sizing: border-box;
      -ms-flex-preferred-size: 16.6666666667%;
          flex-basis: 16.6666666667%;
      max-width: 16.6666666667%;
      padding: .5rem;
    }
    .col-lg-offset-2 {
      margin-left: 16.6666666667%;
    }
    .col-lg-3 {
      box-sizing: border-box;
      -ms-flex-preferred-size: 25%;
          flex-basis: 25%;
      max-width: 25%;
      padding: .5rem;
    }
    .col-lg-offset-3 {
      margin-left: 25%;
    }
    .col-lg-4 {
      box-sizing: border-box;
      -ms-flex-preferred-size: 33.3333333333%;
          flex-basis: 33.3333333333%;
      max-width: 33.3333333333%;
      padding: .5rem;
    }
    .col-lg-offset-4 {
      margin-left: 33.3333333333%;
    }
    .col-lg-5 {
      box-sizing: border-box;
      -ms-flex-preferred-size: 41.6666666667%;
          flex-basis: 41.6666666667%;
      max-width: 41.6666666667%;
      padding: .5rem;
    }
    .col-lg-offset-5 {
      margin-left: 41.6666666667%;
    }
    .col-lg-6 {
      box-sizing: border-box;
      -ms-flex-preferred-size: 50%;
          flex-basis: 50%;
      max-width: 50%;
      padding: .5rem;
    }
    .col-lg-offset-6 {
      margin-left: 50%;
    }
    .col-lg-7 {
      box-sizing: border-box;
      -ms-flex-preferred-size: 58.3333333333%;
          flex-basis: 58.3333333333%;
      max-width: 58.3333333333%;
      padding: .5rem;
    }
    .col-lg-offset-7 {
      margin-left: 58.3333333333%;
    }
    .col-lg-8 {
      box-sizing: border-box;
      -ms-flex-preferred-size: 66.6666666667%;
          flex-basis: 66.6666666667%;
      max-width: 66.6666666667%;
      padding: .5rem;
    }
    .col-lg-offset-8 {
      margin-left: 66.6666666667%;
    }
    .col-lg-9 {
      box-sizing: border-box;
      -ms-flex-preferred-size: 75%;
          flex-basis: 75%;
      max-width: 75%;
      padding: .5rem;
    }
    .col-lg-offset-9 {
      margin-left: 75%;
    }
    .col-lg-10 {
      box-sizing: border-box;
      -ms-flex-preferred-size: 83.3333333333%;
          flex-basis: 83.3333333333%;
      max-width: 83.3333333333%;
      padding: .5rem;
    }
    .col-lg-offset-10 {
      margin-left: 83.3333333333%;
    }
    .col-lg-11 {
      box-sizing: border-box;
      -ms-flex-preferred-size: 91.6666666667%;
          flex-basis: 91.6666666667%;
      max-width: 91.6666666667%;
      padding: .5rem;
    }
    .col-lg-offset-11 {
      margin-left: 91.6666666667%;
    }
    .col-lg-12 {
      box-sizing: border-box;
      -ms-flex-preferred-size: 100%;
          flex-basis: 100%;
      max-width: 100%;
      padding: .5rem;
    }
    .col-lg-offset-12 {
      margin-left: 100%;
    }
    .row.start-lg {
      -ms-flex-pack: start;
          justify-content: flex-start;
    }
    .row.center-lg {
      -ms-flex-pack: center;
          justify-content: center;
    }
    .row.end-lg {
      -ms-flex-pack: end;
          justify-content: flex-end;
    }
    .row.top-lg {
      -ms-flex-align: start;
          align-items: flex-start;
    }
    .row.middle-lg {
      -ms-flex-align: center;
          align-items: center;
    }
    .row.bottom-lg {
      -ms-flex-align: end;
          align-items: flex-end;
    }
    .row.around-lg {
      -ms-flex-pack: distribute;
          justify-content: space-around;
    }
    .row.between-lg {
      -ms-flex-pack: justify;
          justify-content: space-between;
    }
    .first-lg {
      -ms-flex-order: -1;
          order: -1;
    }
    .last-lg {
      -ms-flex-order: 1;
          order: 1;
    }
  }
  
  @media only screen and (min-width: 12.5rem) {
    .col-xl {
      box-sizing: border-box;
      -ms-flex-positive: 1;
          flex-grow: 1;
      -ms-flex-preferred-size: 0;
          flex-basis: 0;
      max-width: 100%;
      padding: .5rem;
    }
    .col-xl-1 {
      box-sizing: border-box;
      -ms-flex-preferred-size: 8.3333333333%;
          flex-basis: 8.3333333333%;
      max-width: 8.3333333333%;
      padding: .5rem;
    }
    .col-xl-offset-1 {
      margin-left: 8.3333333333%;
    }
    .col-xl-2 {
      box-sizing: border-box;
      -ms-flex-preferred-size: 16.6666666667%;
          flex-basis: 16.6666666667%;
      max-width: 16.6666666667%;
      padding: .5rem;
    }
    .col-xl-offset-2 {
      margin-left: 16.6666666667%;
    }
    .col-xl-3 {
      box-sizing: border-box;
      -ms-flex-preferred-size: 25%;
          flex-basis: 25%;
      max-width: 25%;
      padding: .5rem;
    }
    .col-xl-offset-3 {
      margin-left: 25%;
    }
    .col-xl-4 {
      box-sizing: border-box;
      -ms-flex-preferred-size: 33.3333333333%;
          flex-basis: 33.3333333333%;
      max-width: 33.3333333333%;
      padding: .5rem;
    }
    .col-xl-offset-4 {
      margin-left: 33.3333333333%;
    }
    .col-xl-5 {
      box-sizing: border-box;
      -ms-flex-preferred-size: 41.6666666667%;
          flex-basis: 41.6666666667%;
      max-width: 41.6666666667%;
      padding: .5rem;
    }
    .col-xl-offset-5 {
      margin-left: 41.6666666667%;
    }
    .col-xl-6 {
      box-sizing: border-box;
      -ms-flex-preferred-size: 50%;
          flex-basis: 50%;
      max-width: 50%;
      padding: .5rem;
    }
    .col-xl-offset-6 {
      margin-left: 50%;
    }
    .col-xl-7 {
      box-sizing: border-box;
      -ms-flex-preferred-size: 58.3333333333%;
          flex-basis: 58.3333333333%;
      max-width: 58.3333333333%;
      padding: .5rem;
    }
    .col-xl-offset-7 {
      margin-left: 58.3333333333%;
    }
    .col-xl-8 {
      box-sizing: border-box;
      -ms-flex-preferred-size: 66.6666666667%;
          flex-basis: 66.6666666667%;
      max-width: 66.6666666667%;
      padding: .5rem;
    }
    .col-xl-offset-8 {
      margin-left: 66.6666666667%;
    }
    .col-xl-9 {
      box-sizing: border-box;
      -ms-flex-preferred-size: 75%;
          flex-basis: 75%;
      max-width: 75%;
      padding: .5rem;
    }
    .col-xl-offset-9 {
      margin-left: 75%;
    }
    .col-xl-10 {
      box-sizing: border-box;
      -ms-flex-preferred-size: 83.3333333333%;
          flex-basis: 83.3333333333%;
      max-width: 83.3333333333%;
      padding: .5rem;
    }
    .col-xl-offset-10 {
      margin-left: 83.3333333333%;
    }
    .col-xl-11 {
      box-sizing: border-box;
      -ms-flex-preferred-size: 91.6666666667%;
          flex-basis: 91.6666666667%;
      max-width: 91.6666666667%;
      padding: .5rem;
    }
    .col-xl-offset-11 {
      margin-left: 91.6666666667%;
    }
    .col-xl-12 {
      box-sizing: border-box;
      -ms-flex-preferred-size: 100%;
          flex-basis: 100%;
      max-width: 100%;
      padding: .5rem;
    }
    .col-xl-offset-12 {
      margin-left: 100%;
    }
    .row.start-xl {
      -ms-flex-pack: start;
          justify-content: flex-start;
    }
    .row.center-xl {
      -ms-flex-pack: center;
          justify-content: center;
    }
    .row.end-xl {
      -ms-flex-pack: end;
          justify-content: flex-end;
    }
    .row.top-xl {
      -ms-flex-align: start;
          align-items: flex-start;
    }
    .row.middle-xl {
      -ms-flex-align: center;
          align-items: center;
    }
    .row.bottom-xl {
      -ms-flex-align: end;
          align-items: flex-end;
    }
    .row.around-xl {
      -ms-flex-pack: distribute;
          justify-content: space-around;
    }
    .row.between-xl {
      -ms-flex-pack: justify;
          justify-content: space-between;
    }
    .first-xl {
      -ms-flex-order: -1;
          order: -1;
    }
    .last-xl {
      -ms-flex-order: 1;
          order: 1;
    }
  }
  
  .col-gutter-lr {
    padding: 0 .5rem;
  }
  
  .col-no-gutter {
    padding: 0;
  }
  
  .show {
    display: block !important;
  }
  
  .row.show {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  
  .hide {
    display: none !important;
  }
  
  .show-xs {
    display: block !important;
  }
  
  .row.show-xs {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  
  .hide-xs {
    display: none !important;
  }
  
  @media only screen and (max-width: 40rem) {
    .show-xs-only {
      display: block !important;
    }
    .row.show-xs-only {
      display: -ms-flexbox !important;
      display: flex !important;
    }
    .hide-xs-only {
      display: none !important;
    }
  }
  
  @media only screen and (min-width: 4.5rem) {
    .show-sm {
      display: block !important;
    }
    .row.show-sm {
      display: -ms-flexbox !important;
      display: flex !important;
    }
    .hide-sm {
      display: none !important;
    }
  }
  
  @media only screen and (min-width: 4.5rem) and (max-width: 64rem) {
    .show-sm-only {
      display: block !important;
    }
    .row.show-sm-only {
      display: -ms-flexbox !important;
      display: flex !important;
    }
    .hide-sm-only {
      display: none !important;
    }
  }
  
  @media only screen and (min-width: 65rem) {
    .show-md {
      display: block !important;
    }
    .row.show-md {
      display: -ms-flexbox !important;
      display: flex !important;
    }
    .hide-md {
      display: none !important;
    }
  }
  
  @media only screen and (min-width: 65rem) and (max-width: 90rem) {
    .show-md-only {
      display: block !important;
    }
    .row.show-md-only {
      display: -ms-flexbox !important;
      display: flex !important;
    }
    .hide-md-only {
      display: none !important;
    }
  }
  
  @media only screen and (min-width: 9.5rem) {
    .show-lg {
      display: block !important;
    }
    .row.show-lg {
      display: -ms-flexbox !important;
      display: flex !important;
    }
    .hide-lg {
      display: none !important;
    }
  }
  
  @media only screen and (min-width: 9.5rem) and (max-width: 120rem) {
    .show-lg-only {
      display: block !important;
    }
    .row.show-lg-only {
      display: -ms-flexbox !important;
      display: flex !important;
    }
    .hide-lg-only {
      display: none !important;
    }
  }
  
  @media only screen and (min-width: 12.5rem) {
    .show-xl {
      display: block !important;
    }
    .row.show-xl {
      display: -ms-flexbox !important;
      display: flex !important;
    }
    .hide-xl {
      display: none !important;
    }
  }