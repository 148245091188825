@use '../../../sass/theme/app_variables' as *;

.card {
  background: $white;
  border-radius: .25rem;
  box-shadow: $shadow-1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &.headerless {
    .card_header {
      display: none;
    }
  }

  .card_header {
    padding: .75rem 1rem;
    display: flex;
    justify-content: space-between;

    .card_title {
      font-size: .875rem;
      color: $gray;

      svg {
        height: .875rem;
        stroke: $gray;
        margin-right: .5rem;
      }
    }
  }

  &.align_middle {
    .card_body {
      justify-content: space-around;
    }
  }

  &.align_bottom {
    .card_body {
      justify-content: flex-end;
    }
  }

  .card_body {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0 1rem .75rem;
  }
}

/* TODO: (2022-09-08) this should go to _global.scss?  */
@media (max-width: $tablet) {
  :global {

    //hotfix for most of filter icons inside a card
    .MuiBox-root {
      >svg {
        height: 1.25rem;
        width: unset;
      }
    }

    .page-wrapper {

      .page {
        padding: 0;
        height: 100%;

        .page-header {
          padding: 1rem;
        }

        .page-content {
          height: auto;

          .page-body {
            padding-bottom: 5rem;

            .row {
              margin: auto;
              width: 100%;
              justify-content: center;

              .col-xs-6 {
                padding: 0;
                max-width: 100%;
              }

              div[class*="col-xs-"] {
                margin-bottom: 1rem;
                flex-basis: 100%;
              }
            }
          }
        }
      }
    }
  }
}