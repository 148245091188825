// Default colors
$white: #fff;
$gray: #444;
$light-gray: #f1f1f1;
$lighter-gray: #f9f9f9;
$yellow: #F9C623;
$red: #DC3E4D;

// Other styles
$shadow-color: rgba(0, 0, 0, .1);
$shadow: 0 0 8px $shadow-color;
$font-family: 'Raleway', sans-serif !important;
// $font-family: 'Source Sans Pro', sans-serif !important;

// Theme
$primary: #A70D11;
$secondary: #F9C623;
$text: $gray;

// Custom
$atuacao: #009164;
$comercial: #F9C623;
$social: #e46f2a;

//Charts
$chart1: #F9C623;
$chart2: #E46F2A;
$chart3: #58394e;
$chart4: #00664a;
$chart5: #009164;

//screen sizes
$mobileS: '320px';
$mobileM: '375px';
$mobileL: '425px';
$tablet: '768px';
$wide: '1024px';