.button-primary {
  padding: 0 1rem;
  height: 2rem;
  border: 1px solid $primary;
  border-radius: 1rem;
  font-size: .875rem;
  font-weight: 500;
  box-sizing: border-box;
  background: $primary;
  color: $white;
  cursor: pointer;
  font-family: $font-family;
  transition: .5s ease;
  display: flex;
  align-items: center;

  svg {
    height: 1rem;
    margin-right: .25rem;
    margin-left: -0.25rem;
  }
  &.small {
    font-size: .75rem;
    padding: 0 .75rem;
    height: 1.75rem;
  }
  &:hover {
    background: $secondary;
    border: 1px solid $secondary;

    svg {
      animation: Roll .5s ease forwards;
    }
  }

  @keyframes Roll {
    0%{
      transform: rotate(0);
    }
    0%{
      transform: rotate(-360deg);
    }
  }
}
.button-outline {
  padding: 0 1rem;
  height: 2rem;
  border: 1px solid $text;
  border-radius: 1rem;
  font-size: .875rem;
  font-weight: 500;
  box-sizing: border-box;
  background: transparent;
  color: $text;
  cursor: pointer;
  font-family: $font-family;
  transition: .5s ease;
  display: flex;
  align-items: center;

  svg {
    height: 1rem;
    margin-right: .25rem;
    margin-left: -0.25rem;
  }

  &:hover {
    color: $primary;
    background: $secondary;
    border: 1px solid $secondary;

    svg {
      animation: Roll .5s ease forwards;
    }
  }

  @keyframes Roll {
    0%{
      transform: rotate(0);
    }
    0%{
      transform: rotate(-360deg);
    }
  }
}
.button-link {
  font-size: .875rem;
  font-weight: 500;
  color: $text;
  cursor: pointer;
  font-family: $font-family;
  transition: .5s ease;
  display: flex;
  align-items: center;
  background: transparent;
  border: 0;

  svg {
    height: 1rem;
    margin-right: .25rem;
  }

  &:hover {
    color: $primary;
  }
  &.reverse {
      svg {
        margin-right: 0;
        margin-left: .25rem;
      }
  }
  &.gray {
    color: $gray;

    &:hover {
      color: $gray;
      opacity: .75;
    }
  }
}