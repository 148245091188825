// Padding
.p-0 {
  padding: 0;
}
.p-1 {
  padding: .25rem;
}
.p-2 {
  padding: .5rem;
}
.p-3 {
  padding: 1rem;
}
.p-4 {
  padding: 2rem;
}
.p-5 {
  padding: 3rem;
}
.px-0 {
  padding-left: 0;
  padding-right: 0;
}
.px-1 {
  padding-left: .25rem;
  padding-right: .25rem;
}
.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}
.px-3 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.px-4 {
  padding-left: 2rem;
  padding-right: 2rem;
}
.px-5 {
  padding-left: 3rem;
  padding-right: 3rem;
}
.pl-0 {
  padding-left: 0;
}
.pl-1 {
  padding-left: .25rem;
}
.pl-2 {
  padding-left: .5rem;
}
.pl-3 {
  padding-left: 1rem;
}
.pl-4 {
  padding-left: 2rem;
}
.pl-5 {
  padding-left: 3rem;
}
.pr-0 {
  padding-right: 0;
}
.pr-1 {
  padding-right: .25rem;
}
.pr-2 {
  padding-right: .5rem;
}
.pr-3 {
  padding-right: 1rem;
}
.pr-4 {
  padding-right: 2rem;
}
.pr-5 {
  padding-right: 3rem;
}
.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}
.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}
.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}
.py-3 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.py-4 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.py-5 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}
.pt-0 {
  padding-top: 0;
}
.pt-1 {
  padding-top: .25rem;
}
.pt-2 {
  padding-top: .5rem;
}
.pt-3 {
  padding-top: 1rem;
}
.pt-4 {
  padding-top: 2rem;
}
.pt-5 {
  padding-top: 3rem;
}
.pb-0 {
  padding-bottom: 0;
}
.pb-1 {
  padding-bottom: .25rem;
}
.pb-2 {
  padding-bottom: .5rem;
}
.pb-3 {
  padding-bottom: 1rem;
}
.pb-4 {
  padding-bottom: 2rem;
}
.pb-5 {
  padding-bottom: 3rem;
}


// Margin
.m-0 {
  margin: 0;
}
.m-1 {
  margin: .25rem;
}
.m-2 {
  margin: .5rem;
}
.m-3 {
  margin: 1rem;
}
.m-4 {
  margin: 2rem;
}
.m-5 {
  margin: 3rem;
}
.mx-0 {
  margin-left: 0;
  margin-right: 0;
}
.mx-1 {
  margin-left: .25rem;
  margin-right: .25rem;
}
.mx-2 {
  margin-left: .5rem;
  margin-right: .5rem;
}
.mx-3 {
  margin-left: 1rem;
  margin-right: 1rem;
}
.mx-4 {
  margin-left: 2rem;
  margin-right: 2rem;
}
.mx-5 {
  margin-left: 3rem;
  margin-right: 3rem;
}
.ml-0 {
  margin-left: 0;
}
.ml-1 {
  margin-left: .25rem;
}
.ml-2 {
  margin-left: .5rem;
}
.ml-3 {
  margin-left: 1rem;
}
.ml-4 {
  margin-left: 2rem;
}
.ml-5 {
  margin-left: 3rem;
}
.mr-0 {
  margin-right: 0;
}
.mr-1 {
  margin-right: .25rem;
}
.mr-2 {
  margin-right: .5rem;
}
.mr-3 {
  margin-right: 1rem;
}
.mr-4 {
  margin-right: 2rem;
}
.mr-5 {
  margin-right: 3rem;
}
.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}
.my-1 {
  margin-top: .25rem;
  margin-bottom: .25rem;
}
.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}
.my-3 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.my-4 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.my-5 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}
.mt-0 {
  margin-top: 0;
}
.mt-1 {
  margin-top: .25rem;
}
.mt-2 {
  margin-top: .5rem;
}
.mt-3 {
  margin-top: 1rem;
}
.mt-4 {
  margin-top: 2rem;
}
.mt-5 {
  margin-top: 3rem;
}
.mb-0 {
  margin-bottom: 0;
}
.mb-1 {
  margin-bottom: .25rem;
}
.mb-2 {
  margin-bottom: .5rem;
}
.mb-3 {
  margin-bottom: 1rem;
}
.mb-4 {
  margin-bottom: 2rem;
}
.mb-5 {
  margin-bottom: 3rem;
}

// Size
.h-100 {
  height: 100%;
}


// Image
.img-fluid {
  max-width: 100%;
}