.input-text {
    width: 100%;
    background-color: rgba($primary, .05) !important;
    border-radius: .5rem !important;

    &.warning {
        background-color: rgba($yellow, .1) !important;

        fieldset {
            border-color: $yellow !important;
        }
        .MuiInputLabel-root {
            color: $yellow !important;
        }
    }
    &.critical {
        background-color: rgba($red, .1) !important;

        fieldset {
            border-color: $red !important;
        }
        .MuiInputLabel-root {
            color: $red !important;
        }
    }

    .MuiInputLabel-root {
        background: $white;
        transform: translate(.5rem, -.5rem) scale(0.75);
        color: $text;
        padding: 0 .5rem;
    }

    .MuiOutlinedInput-root {
        input {
            height: 2rem;
            padding: .25rem 1rem !important;
            border-radius: .5rem !important;
            line-height: 2rem;
            font-size: .875rem;

            &::placeholder {
                opacity: 0;
                transition: .25s ease;
                color: $text;
            }
        }
        fieldset {
            border: 1px solid transparent;
            border-radius: .5rem;
            transition: .25s ease;
        }
        &.MuiInputBase-adornedStart {

            .MuiInputAdornment-root {
                p {
                    font-size: .875rem;
                    color: $gray;
                }
            }
            input {
                padding: .5rem 1rem .5rem 0;
            }
        }
    }

    &:hover {
        &:not(:focus-within) {
            fieldset {
                border-color: rgba($primary, .25) !important;
            }
        }
    }
    &:focus-within {
        input {
            &::placeholder {
                opacity: .5;
            }
        }
        fieldset {
            border: 1px solid $primary !important;
        }
    }
}
.input-select {
    width: 100%;
    background-color: rgba($primary, .05) !important;
    border-radius: .5rem !important;

    &.warning {
        background-color: rgba($yellow, .1) !important;

        fieldset {
            border-color: $yellow !important;
        }
        .MuiInputLabel-root {
            color: $yellow !important;
        }
    }
    &.critical {
        background-color: rgba($red, .1) !important;

        fieldset {
            border-color: $red !important;
        }
        .MuiInputLabel-root {
            color: $red !important;
        }
    }

    .MuiInputLabel-root {
        background: $white;
        transform: translate(.5rem, -.5rem) scale(0.75);
        padding: 0 .5rem;
    }

    .MuiSelect-select {
        padding: .25rem 1rem;
        font-size: .875rem;
        height: 2rem !important;
        line-height: 2rem;
    }
    fieldset {
        border-color: transparent;
        transition: .25s ease;
    }

    &:hover {
        &:not(:focus-within) {
            fieldset {
                border-color: rgba($primary, .25) !important;
            }
        }
    }
    .Mui-focused {
        fieldset {
            border-color: rgba($primary, .25);
            border-width: 1px !important;
        }
        &:focus-within {
            border-color: $primary !important;
        }
    }
}
.input-autocomplete {
    width: 100%;
    background-color: rgba($primary, .05) !important;
    border-radius: .5rem !important;

    &.warning {
        background-color: rgba($yellow, .1) !important;

        fieldset {
            border-color: $yellow !important;
        }
        .MuiInputLabel-root {
            color: $yellow !important;
        }
    }
    &.critical {
        background-color: rgba($red, .1) !important;

        fieldset {
            border-color: $red !important;
        }
        .MuiInputLabel-root {
            color: $red !important;
        }
    }
    
    .MuiInputLabel-root {
        background: $white;
        transform: translate(.5rem, -.5rem) scale(0.75);
        padding: 0 .5rem;
    }

    .MuiOutlinedInput-root {
        padding: 0 !important;

        input {
            height: 2rem;
            padding: .25rem 1rem !important;
            border-radius: .5rem !important;
            line-height: 2rem;
            font-size: .875rem;
    }
        fieldset {
            border: 1px solid transparent;
            border-radius: .5rem;
            transition: .25s ease;
        }
    }
    &:hover {
        &:not(:focus-within) {
            fieldset {
                border-color: rgba($primary, .25) !important;
            }
        }
    }
    &:focus-within {
        fieldset {
            border: 1px solid $primary !important;
        }
    }
}
.input-datepicker {
    width: 100%;
    background-color: rgba($primary, .05) !important;
    border-radius: .5rem !important;

    &.warning {
        background-color: rgba($yellow, .1) !important;

        fieldset {
            border-color: $yellow !important;
        }
        .MuiInputLabel-root {
            color: $yellow !important;
        }
    }
    &.critical {
        background-color: rgba($red, .1) !important;

        fieldset {
            border-color: $red !important;
        }
        .MuiInputLabel-root {
            color: $red !important;
        }
    }

    .MuiInputLabel-root {
        background: $white;
        transform: translate(.5rem, -.5rem) scale(0.75);
        color: $text;
        padding: 0 .5rem;
    }
    .MuiOutlinedInput-root {
        input {
            height: 2rem;
            padding: .25rem 1rem;
            border-radius: .5rem !important;
            line-height: 2rem;
            font-size: .875rem;

            &::placeholder {
                opacity: .5;
                transition: .25s ease;
                color: $text;
            }
        }
        fieldset {
            border: 1px solid transparent;
            border-radius: .5rem;
            transition: .25s ease;
        }
        .MuiInputAdornment-root {
            button {
                margin: 0;

                svg {
                    fill: $gray;
                    opacity: .5;
                }
                &:hover {
                    svg {
                        fill: $primary;
                        opacity: 1;
                    }
                }
            }
        }
    }

    &:hover {
        &:not(:focus-within) {
            fieldset {
                border-color: rgba($primary, .25) !important;
            }
        }
    }
    &:focus-within {
        input {
            &::placeholder {
                opacity: 0;
            }
        }
        fieldset {
            border: 1px solid $primary !important;
        }
    }
}
.input-daterangepicker {

    &.warning {
        background-color: rgba($yellow, .1) !important;

        fieldset {
            border-color: $yellow !important;
        }
        .MuiInputLabel-root {
            color: $yellow !important;
        }
    }
    &.critical {
        background-color: rgba($red, .1) !important;

        fieldset {
            border-color: $red !important;
        }
        .MuiInputLabel-root {
            color: $red !important;
        }
    }

    .MuiTextField-root {
        width: 100%;
        background-color: rgba($primary, .05) !important;
        border-radius: .5rem !important;
    
        .MuiInputLabel-root {
            background: $white;
            transform: translate(.5rem, -.5rem) scale(0.75);
            color: $text;
            padding: 0 .5rem;
        }
        .MuiOutlinedInput-root {
            input {
                height: 2rem;
                padding: .25rem 1rem;
                border-radius: .5rem !important;
                line-height: 2rem;
                font-size: .875rem;
    
                &::placeholder {
                    opacity: .5;
                    transition: .25s ease;
                    color: $text;
                }
            }
            fieldset {
                border: 1px solid transparent;
                border-radius: .5rem;
                transition: .25s ease;
            }
            .MuiInputAdornment-root {
                button {
                    margin: 0;
    
                    svg {
                        fill: $gray;
                        opacity: .5;
                    }
                    &:hover {
                        svg {
                            fill: $primary;
                            opacity: 1;
                        }
                    }
                }
            }
        }
    
        &:hover {
            &:not(:focus-within) {
                fieldset {
                    border-color: rgba($primary, .25) !important;
                }
            }
        }
        &:focus-within {
            input {
                &::placeholder {
                    opacity: 0;
                }
            }
            fieldset {
                border: 1px solid $primary !important;
            }
        }
        &:first-child {
            margin-bottom: .5rem;
        }
    }
}

.input-textarea {
    width: 100%;
    background-color: rgba($primary, .05) !important;
    border-radius: .5rem !important;

    &.warning {
        background-color: rgba($yellow, .1) !important;

        fieldset {
            border-color: $yellow !important;
        }
        .MuiInputLabel-root {
            color: $yellow !important;
        }
    }
    &.critical {
        background-color: rgba($red, .1) !important;

        fieldset {
            border-color: $red !important;
        }
        .MuiInputLabel-root {
            color: $red !important;
        }
    }

    .MuiInputLabel-root {
        background: $white;
        transform: translate(.5rem, -.5rem) scale(0.75);
        color: $text;
        padding: 0 .5rem;
    }
    
    .MuiOutlinedInput-root {
        padding: 0;

        textarea {
            padding: 1rem;
            border-radius: .5rem !important;
            font-size: .875rem;
        }
        fieldset {
            border: 1px solid transparent;
            border-radius: .5rem;
            transition: .25s ease;
        }
    }

    &:hover {
        &:not(:focus-within) {
            fieldset {
                border-color: rgba($primary, .25) !important;
            }
        }
    }
    &:focus-within {
        input {
            &::placeholder {
                opacity: .5;
            }
        }
        fieldset {
            border: 1px solid $primary !important;
        }
    }
}
.input-switch {
    &.inverted {
        transform: rotate(180deg);

        .MuiSwitch-track {
            background-color: $primary;
            opacity: .75;
        }
        .MuiSwitch-switchBase {
            color: $primary;

            &.Mui-checked{
                color: $white;
    
                & +.MuiSwitch-track {
                    background-color: $gray !important;
                    opacity: .25;
                }
            }
        } 
    }
}
.profile-img {
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    background-color: rgba($primary, .05);
    border-radius: .5rem;
    position: relative;
    overflow: hidden;

    img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
.hr-spacer {
    border-top: 1px solid $lighter-gray;
    margin: 1rem 0;
}
.modal {
    .MuiPaper-root {
        width: 100%;
    }
    .MuiDialogTitle-root {
        font-size: 1rem;
        padding: 1rem 1.5rem;
    }
    .MuiDialogContent-root {
        padding: 1.5rem;
        border-top: 1px solid $light-gray;
        border-bottom: 1px solid $light-gray;

        .MuiTabs-root {
            overflow: visible;

            .MuiTabs-scroller {
                overflow: visible !important;
            }
            .MuiButtonBase-root {
                padding: .5rem 1.5rem;
                border-radius: .5rem .5rem 0 0;
                background: $white;
                text-transform: initial;
                overflow: visible;

                &.Mui-selected {
                    font-weight: bold;
                    box-shadow: $shadow;
                    z-index: 3;

                    &:after {
                        content: '';
                        width: 100%;
                        height: .5rem;
                        background: $white;
                        bottom: -.5rem;
                        left: 0;
                        position: absolute;
                    }
                }
            }
        }
    }
    .MuiDialogActions-root {
        padding: 1rem 1.5rem;
    }
}