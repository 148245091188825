.page-wrapper {
    background: linear-gradient(90deg, rgba($primary,0) 0%, rgba($primary, 0.05) 100%);
    height: calc(100vh - 4.5rem);
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar{
      width: .5rem;
      height: .5rem;
    }
    &::-webkit-scrollbar-thumb{
      background: rgba($primary, 0.75);
      border-radius: .125rem;
    }
    &::-webkit-scrollbar-thumb:hover{
      background: rgba($primary, 1);
    }
    &::-webkit-scrollbar-track{
      background: rgba($primary, 0.25);
    }
    
  
    .page {
      padding: 2rem 3rem;
      width: 100%;
  
      &.width-limiter {
        max-width: 1399px;
        margin: 0 auto;
      }
      &.centered {
        margin: auto;
      }
      &.tbox-fixed {
        height: 100%;
        display: flex;
        flex-direction: column;

        .page-header {
          height: 4.5rem;
        }
        .page-content {
          height: calc(100% - 4.5rem);
          margin: 0 -.5rem;
          padding: 0 .5rem;

          .page-body {
            height: 100%;

            .tablebox {
              height: calc(100% - 3rem);

              &.no_expand {
                height: unset;
              }

              .tbox-body {
                height: calc(100% - 3rem);
                overflow: auto;
              }
            }
          }
        }
      }
      .page-header {
        display: flex;
        justify-content: space-between;
        padding-bottom: 1rem;
  
        .page-header-buttons {
          display: flex;
          align-items: flex-end;
  
          button {
            margin-left: .5rem;
          }
        }
      }
      .page-content {
        display: flex;
  
        .page-sidebar {
          box-shadow: $shadow;
          border-radius: .5rem;
          background: $white;
          min-width: 256px;
          overflow: hidden;
          user-select: none;
          margin-bottom: auto;

          &:first-child {
            margin-right: 1rem;
          }
          &:last-child {
            margin-left: 1rem;
          }
  
          .sidebar-tabs {
            display: flex;
            flex-direction: row;
            align-items: stretch;
            box-shadow: $shadow;
  
            .sidebar-tab {
              height: 3rem;
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              text-transform: lowercase;
              font-weight: 500;
              cursor: pointer;
              transition: .25s ease;
              font-size: .875rem;
  
              &:not(.active):hover {
                opacity: .5;
              }
                          
              &.active {
                background: $primary;
                color: $white;
                pointer-events: none;
                font-weight: 600;
              }
            }
          }
          .sidebar-body {
            padding: 1.5rem 1rem;
            font-size: .875rem;
          }
        }
        .page-body {
          width: 100%;
          position: relative;

          .page-actions {
            height: 3rem;
            margin-bottom: 1rem;
            position: sticky;
            top: 1rem;
            z-index: 99;
          
            .backdrop {
              content: "";
              position: absolute;
              width: calc(100vw - .75rem);
              height: 5rem;
              top: -1rem;
              left: 50%;
              transform: translate(-50%, 0);
              background: $white;
              z-index: -1;
          
              &:after {
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                background: linear-gradient(90deg, rgba($primary,0) 0%, rgba($primary, 0.05) 100%);
              }
            }
            .page-actions-content {
              background: $white;
              border-radius: .5rem;
              display: flex;
              justify-content: space-between;
              box-shadow: $shadow;
              height: 3rem; 
              align-items: center;

              .page-actions-title {
                padding: 0.5rem 1rem;
              }
          
              .page-actions-actions {
                display: flex;
                padding: 0 1rem;
                align-items: center;
                height: 100%;

                .page-actions-filters {
                  display: flex;
                  cursor: pointer;
                  transition: .25s ease;
                  align-items: center;
                  line-height: 1rem;
                  height: 100%;
                  color: $primary;
                  font-weight: 500;

                  &:not(:last-child) {
                    margin-right: 1rem;
                  }

                  svg {
                    height: 1rem;
                    stroke: $primary;
                    margin-right: .25rem;
                  }
                  &:hover {
                    opacity: .5;
                  }

                }

              }
            }          
          }

          .pagination {
            position: absolute;
            right: 0;
            padding: .5rem;

            .MuiTablePagination-toolbar {
              height: 2rem;
              min-height: unset;
            }
          }

          .progress {
            display: flex;
            // justify-content: center;
            cursor: pointer;
            
            .progress-circle {
              width: 2rem;
              height: 2rem;
              background: $white;
              border-radius: 50%;
              position: relative;
              
              .circle {
                .mask {
                  width: 100%;
                  height: 100%;
                  position: absolute;
                  border-radius: 50%;    
                  clip: rect(0px, 2rem, 2rem, 1rem);

                  &.full, .fill {
                    transform: rotate(135deg);
                  }

                  .fill {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    border-radius: 50%;
                    clip: rect(0px, 1rem, 2rem, 0px);
                    background-color: $primary;  
                  }

                }
                .inside-circle {
                  width: calc(100% - .25rem);
                  height: calc(100% - .25rem);
                  border-radius: 50%;
                  background: $white;
                  line-height: 1.625rem;
                  text-align: center;
                  margin-top: .125rem;
                  margin-left: .125rem;
                  color: $primary;
                  position: absolute;
                  font-weight: 700;
                  font-size: 0.70em;
                }
              }
            }
          }
        }  
      }
    }
}
.tablebox {
  box-shadow: $shadow;
  overflow: hidden;
  border-radius: .5rem;
  background: $white;

  .tbox-header {
    box-shadow: $shadow;
    height: 3rem;
    display: flex;
    justify-content: space-between;

    .tbox-search {
      padding: 0.5rem 1rem;
      display: flex;
      align-items: center;
      max-width: unset;
      width: 100%;
      z-index: 9;

      svg {
        height: 1rem;
        stroke: $text;
        pointer-events: none;
        transition: .25s ease;
      }

      input {
        margin: 0 0 0 0.25rem;
        padding: 0;
        height: 2rem;
        border: 0;
        font-size: 1rem;
        transition: .25s ease;
        text-decoration: none !important;

        &::placeholder {
          color: $text;
        }

        &:focus {
          color: $primary;
        }
      }

      &:focus-within {
        svg {
          stroke: $primary;
        }
        input{
          &::placeholder {
            opacity: .5;
          }
        }
      }
    }
    .tbox-buttons {
      margin-left: 1rem;
      display: flex;
      align-items: center;
      user-select: none;

      .tbox-button {
        padding: 0 1rem;
        background: transparent;
        border: 0;
        font-size: 1rem;
        font-weight: 500;
        color: $gray;
        cursor: pointer;
        font-family: $font-family;
        transition: .25s ease;
        display: flex;
        align-items: center; 
        
        svg {
          height: 1rem;
          margin-right: .5rem;
          stroke: $text;
          }
      
        &:hover {
          opacity: .5;
        }           
      }
    }
  }
  .tbox-body {
    .tbox-table {
      width: 100%;
      border-collapse: collapse;
      
      thead {
        position: sticky;
        top: 0;
        z-index: 1;
        background: $white;
        box-shadow: inset  0 8px 8px -8px rgba(0,0,0,.1);

        th {
          padding: .75rem 1rem;
          color: $text;
          font-size: .875rem;
          text-align: left;

          .tbox-table-sortlabel {
            width: 100%;

            svg {
              height: 1rem;
              stroke: $text;
            }
          }
          &:last-child {
            text-align: center;
          }
        }
      }
      tbody {
        tr {
          td {
            padding: .75rem 1rem;
            color: $text;
            font-size: .875rem;

            &.tbox-table-actions {
              display: flex;
              justify-content: center;
              
              div {
                display: flex;
                margin-right: .5rem;
                cursor: pointer;
                transition: .25s ease;

                svg {
                  height: 1rem;
                  stroke: $text;
                }

                &:last-child {
                  margin-right: 0;
                }
                &:hover {
                  opacity: .5;
                }
              }
            }
            &.text-right {
              text-align: right;
            }
            .input-text {
              border-radius: 0 !important;

              input {
                padding: .25rem .5rem !important;
                height: 1.5rem;
                line-height: 1.5rem;
              }
              p {
                font-size: .875rem;
              }
              fieldset {
                border-radius: 0;
                border: 0;
              }
            }
          }

          &:nth-child(odd) {
            background: rgba($primary, 0.05);

            td {
              .input-text {
                background-color: $white !important;
              }
            }
          }
          &:nth-child(even) {
            td {
              .input-text {
                background-color: rgba($primary, 0.05) !important;
              }
            }
          }
        }
      }
    }
  }

  &.fixed {
    .tbox-body {
      height: calc(100vh - 19rem);
      overflow: auto;  
    }
  }
  &.no-shadow {
    box-shadow: none;
    .tbox-body {
      .tbox-table {
        thead {
          box-shadow: none;
        }
      }
    }  
  }
  &.selectable {
    .tbox-body {
      .tbox-table {
        thead {
          th {
            &:first-child {
              width: 3rem;
            }
          }
        }
      }
    }  

  }        
}
.page-actions-popover {
  .MuiPaper-root {
    max-width: 300px;
    padding: 1.5rem 1rem;
    border-radius: .25rem;
    box-shadow: $shadow;
    display: flex;
    flex-direction: column;
    align-items: center;

    > div {

      &:not(:last-child) {
        margin-bottom: 1rem;
      }
    }
  }
}
